import React  from "react"
import { graphql } from "gatsby"
import Swiper from "react-id-swiper"

import SEO from "../../components/SEO"
import Heading from "../../components/Heading"
import Image from "../../components/Image"
// import { StoryGrid } from "./ss21"

import styles from "./ss21.module.scss"

const HS21 = ({ data, location }) => {
  // const {
  //   nodes
  // } = data.allProductStyle

  // const [swiper, getSwiper] = useState(null)

  const swiperParams = {
    // getSwiper: getSwiper,
    containerClass: `${styles.swiperContainer} swiper-container`,
    WrapperEl: "ol",
    // wrapperClass: `${styles.swiperWrapper} swiper-wrapper`,
    threshold: 10,
    // watchOverflow: true,
    // preloadImage: true,
    // updateOnImagesReady: true,
    spaceBetween: 0,
    slidesPerView: 1,
    loop: true,
    pagination: {
      el: `${styles.swiperPagination} .swiper-pagination`,
      clickable: true,
    },
    allowTouchMove: false,
  }

  return (
    <div className={styles.hs21}>
      <SEO
        title="High Summer '21"
        description="New Collection"
        url={location.pathname}
      />

      <div className={styles.module}>
        <Swiper {...swiperParams}>
          <li
            key={1}
            className={styles.swiperSlide}
          >
            <Image
              image={{
                desktopImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/0_Hero_1320x.jpg?v=1620848255",
                mobileImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/0_Hero_850x.jpg?v=1620848255",
              }}
              altText="High Summer 21 Carousel Image"
            />
          </li>
        </Swiper>
        {/*<button*/}
        {/*  className={`${styles.swiperButtonPrev} swiper-navigation-prev`}*/}
        {/*  onClick={() => swiper.slidePrev()}*/}
        {/*/>*/}
        {/*<button*/}
        {/*  className={`${styles.swiperButtonNext} swiper-navigation-next`}*/}
        {/*  onClick={() => swiper.slideNext()}*/}
        {/*/>*/}
      </div>

      <header className={styles.header}>
        <Heading
          tag="h1"
          level="0"
          className={styles.heading}
        >
          High Summer '21
        </Heading>
      </header>

      {/*<StoryGrid*/}
      {/*  storyName="Tiny Knots"*/}
      {/*  storyImage="https://cdn.shopify.com/s/files/1/1103/4464/files/1_Dream.jpg?v=1620847944"*/}
      {/*  nodes={[*/}
      {/*    "dream-weaver-nappa-sandals",*/}
      {/*    "dream-catcher-nappa-sandals",*/}
      {/*    "dream-maker-nappa-sandals",*/}
      {/*  ].map(handle => nodes.find(style => style.handle === handle))}*/}
      {/*/>*/}

      {/*<StoryGrid*/}
      {/*  storyName="Stones"*/}
      {/*  storyImage="https://cdn.shopify.com/s/files/1/1103/4464/files/2_Stone.jpg?v=1620847945"*/}
      {/*  nodes={nodes.filter(n => [*/}
      {/*    "rock-solid-suede-sandals",*/}
      {/*    "stone-cold-suede-sandals",*/}
      {/*  ].includes(n.handle))}*/}
      {/*/>*/}

      {/*<StoryGrid*/}
      {/*  storyName="Leaves"*/}
      {/*  storyImage="https://cdn.shopify.com/s/files/1/1103/4464/files/3_Botanica.jpg?v=1620847945"*/}
      {/*  nodes={[*/}
      {/*    "new-leaf-metallic-nappa-sandals",*/}
      {/*    "botanica-metallic-nappa-sandals",*/}
      {/*  ].map(handle => nodes.find(style => style.handle === handle))}*/}
      {/*/>*/}

      {/*<StoryGrid*/}
      {/*  storyName="Graphic"*/}
      {/*  storyImage="https://cdn.shopify.com/s/files/1/1103/4464/files/4_Sex.jpg?v=1620847944"*/}
      {/*  nodes={nodes.filter(n => [*/}
      {/*    "telegraphic-metallic-nappa-sandals",*/}
      {/*    "sex-on-the-beach-metallic-nappa-sandals",*/}
      {/*  ].includes(n.handle))}*/}
      {/*/>*/}

      {/*<StoryGrid*/}
      {/*  storyName="Shiny Crochet"*/}
      {/*  storyImage="https://cdn.shopify.com/s/files/1/1103/4464/files/5_Crochet.jpg?v=1621527281"*/}
      {/*  nodes={[*/}
      {/*    "in-the-buff-sandals",*/}
      {/*    "au-naturel-sandals",*/}
      {/*  ].map(handle => nodes.find(style => style.handle === handle))}*/}
      {/*/>*/}
    </div>
  )
}

export default HS21

export const query = graphql`
    query HS21Query {
        allProductStyle(filter: {handle: {in: [
#            "dream-catcher-nappa-sandals",
#            "dream-maker-nappa-sandals",
#            "dream-weaver-nappa-sandals",
#            "rock-solid-suede-sandals",
#            "stone-cold-suede-sandals",
#            "botanica-metallic-nappa-sandals",
#            "new-leaf-metallic-nappa-sandals",
#            "telegraphic-metallic-nappa-sandals",
#            "sex-on-the-beach-metallic-nappa-sandals",
#            "in-the-buff-sandals",
#            "au-naturel-sandals",
        ]}}) {
            nodes {
                ...PLPStyleFragment
                handle
            }
        }
    }
`
